@import '~ag-grid-community/src/styles/ag-grid';
@import '~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin';

// DOCS: https://www.ag-grid.com/angular-data-grid/themes-customising/

.ag-theme-alpine {
  @include ag-theme-alpine(
    (
      background-color: #ffffff,
      foreground-color: var(--nio-colors-gray-70, #606060),
      disabled-foreground-color: rgba(96, 96, 96, 0.5),
      header-background-color: var(--nio-colors-background-tableHeader, #f8fafe),
      border-color: var(--nio-colors-gray-30, #ebebeb),
      font-family: (
        var(--nio-fonts-body, '--aple-system, Helvetica'),
      ),
      font-size: var(--nio-fontSizes-sm, 14px),
      odd-row-background-color: null,
      secondary-border-color: null,
      row-hover-color: var(--nio-colors-primary-50, #f4f7fb),
      input-disabled-border-color: var(--nio-colors-gray-30, #ebebeb),
      input-disabled-background-color: var(--nio-colors-gray-20, #f5f5f5),
      chip-background-color: var(--nio-colors-gray-20, #f5f5f5),
      header-column-resize-handle-color: var(--nio-colors-gray-40, #d8d8d8),
    )
  );

  .ag-header-cell {
    .ag-header-cell-label {
      color: var(--nio-colors-gray-90, #202020);
      font-size: var(--nio-fontSizes-sm, 14px);
      font-weight: var(--nio-fontWeights-medium, 500);
    }
  }

  .ag-row {
    border-bottom: var(--nio-borders-1px, '1px solid');
    border-bottom-color: var(--nio-colors-gray-30, #ebebeb);

    .ag-cell-value {
      align-items: center;
      display: flex;

      .ag-react-container {
        height: 100%;
        width: 100%;
      }

      color: var(--nio-colors-gray-90, #202020);
      font-size: var(--nio-fontSizes-sm, 14px);
      font-weight: var(--nio-fontWeights-normal, 400);

      &.highlight {
        font-weight: var(--nio-fontWeights-medium, 500);
      }

      &.ag-cell-focus.no-outline {
        border-color: transparent;
      }
    }
  }
}
